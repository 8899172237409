import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop ';
import { LenisProvider, MagazineProvider } from './context/provider';
import Loading from './components/other/Loading';

// import Header from './components/layout/Header';
// import Footer from './components/layout/Footer';
// import Home from './pages/Home';
// import Philosophy from './pages/Philosophy';
// import VnsTeam from './pages/VnsTeam';
// import Partners from './pages/Partners';
// import Contact from './pages/Contact';
// import MagazinePage from './pages/MagazinePage';
// import MagazineListPage from './pages/MagazineListPage';
// import MagazineDetailPage from './pages/MagazineDetailPage';

const Header = lazy(() => import('./components/layout/Header'));
const Footer = lazy(() => import('./components/layout/Footer'));
const Home = lazy(() => import('./pages/Home'));
const Philosophy = lazy(() => import('./pages/Philosophy'));
const VnsTeam = lazy(() => import('./pages/VnsTeam'));
const Partners = lazy(() => import('./pages/Partners'));
const Contact = lazy(() => import('./pages/Contact'));
const MagazinePage = lazy(() => import('./pages/MagazinePage'));
const MagazineListPage = lazy(() => import('./pages/MagazineListPage'));
const MagazineDetailPage = lazy(() => import('./pages/MagazineDetailPage'));

const App = () => {
  return (
    <LenisProvider>
      <MagazineProvider>
      <div id="wrap">
        <ScrollToTop />
        <Suspense
          fallback={
            <Loading />
          }
        >
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/philosophy" element={<Philosophy />}></Route>
            <Route path="/vnsteam" element={<VnsTeam />}></Route>
            <Route path="/partners" element={<Partners />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/magazine" element={<MagazinePage />}></Route>
            <Route path="/magazine/list" element={<MagazineListPage />}></Route>
            <Route path="/magazine/view/:postNum" element={<MagazineDetailPage />}></Route>
          </Routes>
          <Footer />
        </Suspense>
      </div>
      </MagazineProvider>
    </LenisProvider>
  );
};

export default App;
