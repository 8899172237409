import { useEffect, useState } from 'react';

// test
import testImg from '../assets/images/test.jpg';

// import
// video
import mainVideo01 from '../assets/videos/main.mp4';
import googleEarthVideo01 from '../assets/videos/googleEarth.mov';
import smokeVideo01 from '../assets/videos/magazine_smoke.mp4';

// menu
import mainImgMenu02 from '../assets/images/menu/menu_02.jpg';
import mainImgMenu03 from '../assets/images/menu/menu_03.jpg';

// img
import logo from '../assets/images/logo.png';
import logoColor from '../assets/images/logo_color.png';
import mainImg01 from '../assets/images/main_01.jpg';
import mainImg02 from '../assets/images/main_02.jpg';
import mainImg03 from '../assets/images/main_03.jpg';
import mainImg04 from '../assets/images/main_04.png';
import mainImg05 from '../assets/images/main_05.jpg';
// icon
import mainArrowRight from '../assets/images/icon/icon_arrow_right.svg';
import mainArrowMiddleRight from '../assets/images/icon/icon_arrow_middleRight.svg';
import mainArrowLongRight from '../assets/images/icon/icon_arrow_longRight.svg';
// imgbox1
import mainImgBox1Img01 from '../assets/images/imgBox/box1_01.jpg';
import mainImgBox1Img02 from '../assets/images/imgBox/box1_02.jpg';
import mainImgBox1Img03 from '../assets/images/imgBox/box1_03.jpg';
import mainImgBox1Img04 from '../assets/images/imgBox/box1_04.jpg';
import mainImgBox1Img05 from '../assets/images/imgBox/box1_05.jpg';
import mainImgBox1Img06 from '../assets/images/imgBox/box1_06.jpg';
import mainImgBox1Img07 from '../assets/images/imgBox/box1_07.jpg';
import mainImgBox1Img08 from '../assets/images/imgBox/box1_08.jpg';
import mainImgBox1Img09 from '../assets/images/imgBox/box1_09.jpg';
import mainImgBox1Img10 from '../assets/images/imgBox/box1_10.jpg';
import mainImgBox1Img11 from '../assets/images/imgBox/box1_11.jpg';
import mainImgBox1Img12 from '../assets/images/imgBox/box1_12.jpg';
import mainImgBox1Img13 from '../assets/images/imgBox/box1_13.jpg';
import mainImgBox1Img14 from '../assets/images/imgBox/box1_14.jpg';
import mainImgBox1Img15 from '../assets/images/imgBox/box1_15.jpg';
import mainImgBox1Img16 from '../assets/images/imgBox/box1_16.jpg';
import mainImgBox1Img17 from '../assets/images/imgBox/box1_17.jpg';
import mainImgBox1Img18 from '../assets/images/imgBox/box1_18.jpg';
import mainImgBox1Img19 from '../assets/images/imgBox/box1_19.jpg';
import mainImgBox1Img20 from '../assets/images/imgBox/box1_20.jpg';
import mainImgBox1Img21 from '../assets/images/imgBox/box1_21.jpg';
import mainImgBox1Img22 from '../assets/images/imgBox/box1_22.jpg';
// imgbox2
import mainImgBox2Left01 from '../assets/images/imgBox2/box2_left_1.png';
import mainImgBox2Left02 from '../assets/images/imgBox2/box2_left_2.png';
import mainImgBox2Left03 from '../assets/images/imgBox2/box2_left_3.png';
import mainImgBox2Center from '../assets/images/imgBox2/box2_center.jpg';
import mainImgBox2Right01 from '../assets/images/imgBox2/box2_right_1.png';
import mainImgBox2Right02 from '../assets/images/imgBox2/box2_right_2.png';
import mainImgBox2Right03 from '../assets/images/imgBox2/box2_right_3.png';
// imgbox3
import mainImgBox3Left from '../assets/images/imgBox3/box3_left.jpg';
import mainImgBox3Center from '../assets/images/imgBox3/box3_center.jpg';
import mainImgBox3Right from '../assets/images/imgBox3/box3_right.jpg';
// partners
import ptThoyota from '../assets/images/partners/pt_thoyota.jpg';
import ptThoyotaM from '../assets/images/partners/pt_thoyota_m.jpg';
import ptThoyotaLogo from '../assets/images/partners/pt_thoyota_logo.png';
import ptLexus from '../assets/images/partners/pt_lexus.jpg';
import ptLexusM from '../assets/images/partners/pt_lexus_m.jpg';
import ptLexusLogo from '../assets/images/partners/pt_lexus_logo.png';
import ptKonai from '../assets/images/partners/pt_konai.jpg';
import ptKonaiM from '../assets/images/partners/pt_konai_m.jpg';
import ptKonaiLogo from '../assets/images/partners/pt_konai_logo.png';
import ptTheHanok from '../assets/images/partners/pt_theHanok.jpg';
import ptTheHanokM from '../assets/images/partners/pt_theHanok_m.jpg';
import ptTheHanokLogo from '../assets/images/partners/pt_theHanok_logo.png';
import ptIbk from '../assets/images/partners/pt_ibk.jpg';
import ptIbkM from '../assets/images/partners/pt_ibk_m.jpg';
import ptIbkLogo from '../assets/images/partners/pt_ibk_logo.png';
import ptBusan from '../assets/images/partners/pt_busan.jpg';
import ptBusanM from '../assets/images/partners/pt_busan_m.jpg';
import ptBusanLogo from '../assets/images/partners/pt_busan_logo.png';
import ptPohang from '../assets/images/partners/pt_pohang.jpg';
import ptPohangM from '../assets/images/partners/pt_pohang_m.jpg';
import ptPohangLogo from '../assets/images/partners/pt_pohang_logo.png';
import ptSamsungHealth from '../assets/images/partners/pt_samsungHealth.jpg';
import ptSamsungHealthM from '../assets/images/partners/pt_samsungHealth_m.jpg';
import ptSamsungHealthLogo from '../assets/images/partners/pt_samsungHealth_logo.png';
// vnsTeam
import vnsImg01 from '../assets/images/vnsTeam/vns_img_01.jpg';
import vnsImg02 from '../assets/images/vnsTeam/vns_img_02.jpg';
import vnsImg03 from '../assets/images/vnsTeam/vns_img_03.jpg';
import vnsImg04 from '../assets/images/vnsTeam/vns_img_04.jpg';
import bella from '../assets/images/vnsTeam/vns_bella.jpg';
import keyle from '../assets/images/vnsTeam/vns_kyle.jpg';
import hoodie from '../assets/images/vnsTeam/vns_hoodie.jpg';
import henry from '../assets/images/vnsTeam/vns_henry.jpg';
import nason from '../assets/images/vnsTeam/vns_nason.jpg';

// text
// main
const mainText01 = [
  'RELAXATION',
  'FOCUS',
  'BALANCE',
  'RELEASE',
  'TRANSFORM',
  'PERFORM',
  'MINDFULNESS',
  'CLARITY',
  'ENERGY',
  'RELIEF',
  'mindset',
  'INNER PEACE',
  'imagine',
];
const mainText02 = ['imagein', 'LEARN', 'MANAGE', 'Diversity'];
const mainText03 = ['think', 'connect', 'mind', 'cowork'];
// vnsTeam
const vnsText01 = ['Honest and', 'trustworthy', 'partner.'];
const vnsMember = [
  {
    name: 'Bella',
    role: 'Project leader',
    email: 'bella@vanishst.com',
    skills: ['Momentum', 'Collective inclusion', 'Artistic flair'],
    img: bella,
  },
  {
    name: 'Kyle',
    role: 'Developer',
    email: 'kyle@vanishst.com',
    skills: ['Create a vision', 'Development', 'Cooler heads'],
    img: keyle,
  },
  {
    name: 'Hoodie',
    role: 'Planner',
    email: 'hoodie@vanishst.com',
    skills: ['Different perspectives', 'Design', 'Communication and collaboration'],
    img: hoodie,
  },
  {
    name: 'Henry',
    role: 'AI Developer',
    email: 'henry@vanishst.com',
    skills: ['Artificial intelligence', 'Flexible thinking', 'Emotional regulation'],
    img: henry,
  },
  {
    name: 'Nason',
    role: 'Developer',
    email: 'nason@vanishst.com',
    skills: ['Artificial intelligence', 'Flexible thinking', 'Emotional regulation'],
    img: nason,
  },
];

// menu
const mainImgMenu = [
  { name: 'vandi project', link: 'https://vandiml.com', img: mainImgBox2Center },
  { name: 'Philosophy', link: '/philosophy', img: mainImgMenu02 },
  { name: 'VNS team', link: '/vnsteam', img: mainImgMenu03 },
];
const mainTextMenu = {
  main: [
    { name: 'home', link: '/' },
    { name: 'partners', link: '/partners' },
  ],
  sub: [
    { name: 'Contact', link: '/contact' },
    { name: 'Magazine', link: '/magazine' },
  ],
};

// export
// video
export const videoMain01 = mainVideo01;
export const videoGoogleEarth01 = googleEarthVideo01;
export const videoSmoke01 = smokeVideo01;

// img
// main
export const mainLogo = logo;
export const colorLogo = logoColor;
export const imgMain01 = mainImg01;
export const imgMain02 = mainImg02;
export const imgMain03 = mainImg03;
export const imgMain04 = mainImg04;
export const imgMain05 = mainImg05;
// imgbox1
export const imgBox1Img = [
  mainImgBox1Img01,
  mainImgBox1Img02,
  mainImgBox1Img03,
  mainImgBox1Img04,
  mainImgBox1Img05,
  mainImgBox1Img06,
  mainImgBox1Img07,
  mainImgBox1Img08,
  mainImgBox1Img09,
  mainImgBox1Img10,
  mainImgBox1Img11,
  mainImgBox1Img12,
  mainImgBox1Img13,
  mainImgBox1Img14,
  mainImgBox1Img15,
  mainImgBox1Img16,
  mainImgBox1Img17,
  mainImgBox1Img18,
  mainImgBox1Img19,
  mainImgBox1Img20,
  mainImgBox1Img21,
  mainImgBox1Img22,
];
// imgbox2
export const imgBox2Left01 = mainImgBox2Left01;
export const imgBox2Left02 = mainImgBox2Left02;
export const imgBox2Left03 = mainImgBox2Left03;
export const imgBox2Center = mainImgBox2Center;
export const imgBox2Right01 = mainImgBox2Right01;
export const imgBox2Right02 = mainImgBox2Right02;
export const imgBox2Right03 = mainImgBox2Right03;
// icon
export const arrowRight = mainArrowRight;
export const arrowMiddleRight = mainArrowMiddleRight;
export const arrowLongRight = mainArrowLongRight;
// imgbox3
export const imgBox3Left = mainImgBox3Left;
export const imgBox3Center = mainImgBox3Center;
export const imgBox3Right = mainImgBox3Right;
// partners
export const imgPartners = [
  { name: 'thoyota', img: ptThoyota, mimg: ptThoyotaM, logo: ptThoyotaLogo },
  { name: 'lexus', img: ptLexus, mimg: ptLexusM, logo: ptLexusLogo },
  { name: 'konai', img: ptKonai, mimg: ptKonaiM, logo: ptKonaiLogo },
  { name: 'theHanok', img: ptTheHanok, mimg: ptTheHanokM, logo: ptTheHanokLogo },
  { name: 'ibk Bank', img: ptIbk, mimg: ptIbkM, logo: ptIbkLogo },
  { name: 'Busan city', img: ptBusan, mimg: ptBusanM, logo: ptBusanLogo },
  { name: 'Pohang city', img: ptPohang, mimg: ptPohangM, logo: ptPohangLogo },
  {
    name: 'samsungHealth',
    img: ptSamsungHealth,
    mimg: ptSamsungHealthM,
    logo: ptSamsungHealthLogo,
  },
];
// vnsTeam
export const imgVns01 = vnsImg01;
export const imgVns02 = vnsImg02;
export const imgVns03 = vnsImg03;
export const imgVns04 = vnsImg04;

// text
// main
export const textMain01 = mainText01;
export const textMain02 = mainText02;
export const textMain03 = mainText03;
// vns team
export const textVns01 = vnsText01;
export const memberVns = vnsMember;

// menu
export const imgMenu02 = mainImgMenu02;
export const imgMenu03 = mainImgMenu03;
export const imgMenu = mainImgMenu;
export const textMenu = mainTextMenu;

// variable
export const mobileWidth = 430;
export const tabletWidth = 760;
export const desktopWidth = 1600;

/**
 * @returns {number} 현재 창의 innerWidth 값
 * @example
 * 사용하고자 하는 컴포넌트에서 아래와 같이 호출하여 사용
 * import useInnerWidth from './useInnerWidth'; // 커스텀 훅이 정의된 파일의 경로
*/
export const useInnerWidth = () => {
  // 현재 창 너비를 반환 함수
  const getInnerWidth = () => {
    return window.innerWidth;
  };

  const [innerWidth, setInnerWidth] = useState(getInnerWidth());

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(getInnerWidth());
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return innerWidth;
};

/**
 * 이미지의 로드 상태를 파악하는 커스텀 훅
 * @param ref 로드 상태를 파악하고자 하는 대상
 * @param src 이미지의 소스
 * @returns 이미지 로드 상태
 * @example
 * 사용하고자 하는 컴포넌트에서 아래와 같이 호출하여 사용
 * import useImgLoadStatus from './useImgLoadStatus'; // 커스텀 훅이 정의된 파일의 경로
 */
export function useImgLoadStatus(ref, src) {
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isImgLoaded) {
      setIsImgLoaded(false);
    }

    const imgElement = ref.current;

    const updateStatus = () => {
      const isLoaded = imgElement.complete && imgElement.naturalHeight !== 0;
      setIsImgLoaded(isLoaded);
    };

    imgElement.addEventListener('load', updateStatus, { once: true });

    updateStatus();

    return () => {
      imgElement.removeEventListener('load', updateStatus);
    };
  }, [src, ref, isImgLoaded]);

  return isImgLoaded;
}


// test
export const testPost = [
  {
    postNum: 1,
    createdAt: '2024.06.22',
    views: 10,
    title: 'vanish stereotype Landing in Korea',
    image: [testImg, imgMain03, mainImg01],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 2,
    createdAt: '2024.06.23',
    views: 11,
    title: 'vanish stereotype Landing in Korea',
    image: [imgMain03, mainImg01, testImg],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 3,
    createdAt: '2024.06.24',
    views: 12,
    title: 'vanish stereotype Landing in Korea',
    image: [mainImg01, testImg, imgMain03],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 4,
    createdAt: '2024.06.25',
    views: 13,
    title: 'vanish stereotype Landing in Korea',
    image: [testImg, imgMain03, mainImg01],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 5,
    createdAt: '2024.06.26',
    views: 14,
    title: 'vanish stereotype Landing in Korea',
    image: [imgMain03, mainImg01, testImg],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 6,
    createdAt: '2024.06.27',
    views: 15,
    title: 'vanish stereotype Landing in Korea',
    image: [mainImg01, testImg, imgMain03],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
  {
    postNum: 7,
    createdAt: '2024.06.28',
    views: 16,
    title: 'vanish stereotype Landing in Korea',
    image: [testImg, imgMain03, mainImg01],
    desc: `April 2023 marked a pivotal moment in our journey as we embarked on countless hours of 
research and engaged in numerous discussions with a multitude of individuals. 

Finally, in 2024, we proudly made our inaugural landing in South Korea under the banner of 
"VANISH STEREOTYPE." This momentous occasion fills us with immeasurable joy, 

as it signifies the realization of our long-held dreams and aspirations. The hope that 
we can bring our vision to life, coupled with the profound satisfaction of witnessing 
its manifestation, is truly beyond words. We pledge to dedicate ourselves wholeheartedly to 
ensuring that the fruits of our preparation do not go to waste. It is our deepest desire to 
present this masterpiece to you, a testament to the tireless efforts of countless individuals, 
and to bestow upon them the recognition they so rightfully deserve. 

We invite you to stand alongside us as we take the first steps of VANISH STEREOTYPE, 
and to join hands as we embark on this brilliant adventure, this new beginning, 
and this transformative journey. In 2024, VANISH STEREOTYPE has made 
its triumphant arrival in South Korea.`,
  },
];
