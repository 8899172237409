import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLenis } from '../context/provider';

const ScrollToTop = () => {
  let location = useLocation();
  const lenisRef = useLenis();

  useEffect(() => {
    window.scrollTo(0, 0);
    // lenis 인스턴스가 존재하면 start() 호출
    if (lenisRef.current) {
      lenisRef.current.start();
    }
  }, [location, lenisRef]);

  return null;
};

export default ScrollToTop;
