import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Lenis from '@studio-freight/lenis';
// 테스트용
import { testPost } from '../utils';

// Lenis
const LenisContext = createContext();

export const useLenis = () => useContext(LenisContext);

export const LenisProvider = ({ children }) => {
  const lenisRef = useRef(null);

  useEffect(() => {
    const lenisInstance = new Lenis({
      duration: 2.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      autoResize: true // 창 크기 변경 시 자동으로 조정
    });

    function raf(time) {
      lenisInstance.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenisRef.current = lenisInstance;

    // Cleanup
    return () => {
      lenisInstance.destroy();
    };
  }, []);

  return (
    <LenisContext.Provider value={lenisRef}>
      {children}
    </LenisContext.Provider>
  );
};

// Magazine
const MagazineContext = createContext();

export const useMagazine = () => useContext(MagazineContext);

export const MagazineProvider = ({ children }) => {
  const [magazineData, setMagazineData] = useState([]);
  const [magazineCount, setMagazineCount] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // 여기에 실제 API 호출 코드를 추가합니다.
        // const response = await fetch('/api/magazine');
        // const data = await response.json();

        // 테스트용 데이터
        const data = testPost;
        const count = testPost.length;

        if (!data) {
          console.log('No data retrieved from the server.')
          return;
        }

        setMagazineData(data);
        setMagazineCount(count);
      } catch (error) {
        console.log('An error occurred while retrieving data:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <MagazineContext.Provider value={{ magazineData, magazineCount }}>
      {children}
    </MagazineContext.Provider>
  );
};