  import React from 'react';

  const Loading = () => {
    return (
      <div className="loading_container" role="alert" aria-live="assertive">
        <span className="loader" aria-label="Loading..."></span>
      </div>
    );
  };

  export default Loading;
